body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  background-color: transparent;
}

.title {
  text-align: center;
  padding-top: 5em;
  .responsive {
    padding: 2em;
    width: 60vw;
    @media(min-width: $mobile-breakpoint-min) {
      height: 55vh;
    }
  }
}

.bg_welcome {
  background-image: url("../images/weave-bg.gif");
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  // on phones, the rules below can mean the
  // bg gif is lost on scroll. We need these
  // on larger screens so the gif fills the screen
  @media(min-width: $mobile-breakpoint-min){

  }
}

a {
  color: black;
  text-decoration: none;
  font-weight:  bold;
}

.row {
  position: relative;
  max-width:  100%;
  text-align:  center;
  padding-bottom: 100px;
  align-items: center;
  justify-content: center;

}

.column {
  font-family: 'DM Sans', sans-serif;
  padding-top: 20px;
  display: inline-flex;
}

.footer {
  color: #000000;
  background-color: transparent;
  text-align: center;
  flex: 50%;
  padding: 1em;
  font-size: 12px;

  a:hover{
    background-color: #000000;
    color:  white;
    text-decoration: none;
  }
}

.locale-picker {
  padding-bottom: 2em;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }

  li {
    padding: 0 0.7em;
  }
}

.caption{
  padding: 1em;
  text-align: center;

  h2 {
    font-size: 1.5em;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    @media (max-width: $mobile-breakpoint-max) {
      font-size: 1em;
    }
  }
  // max-width: 1050px;
  // max-width: 120%;
  // height:  auto;
  // text-align:  center;
  // padding-bottom: 50px;
  // padding-top: 40px;
  // margin-left:  10px;
  // margin-right:  10px;
}

.button-container {
  padding-top: 2em;
  padding-bottom: 5em;
}

.button {
  padding: 5px 32px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 16px;
  margin-top: .5em;
  margin-bottom: .5em;
  margin-left: 3em;
  margin-right: 3em;
  transition-duration: 0.4s;
  cursor: pointer;
  background-color: transparent;
  color: black;
  border: 3px solid #000000;
  border-radius: 40px;
  font-family: 'DM Sans', sans-serif;
}

.button:hover {
  background-color: #000000;
  color: white;
}

.button a:hover {
  background-color: #000000;
  color: white;
  text-decoration: none;
}
