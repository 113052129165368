#app {
  background-image: url("../images/Texere_BG_02.gif");
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.page-container {
  display: flex;
  font-family: 'DM Sans', serif;
  justify-content: left;
  margin-left: 5%;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 5%;
}

.small-logo {
  height: 8em;
  padding-left: 2em;
  @media (max-width: $mobile-breakpoint-max) {
    display: none;
  }
}

.work-area {
  bottom: 0;
  //background-color: #2b141360;
  color: black;
  padding: 2em;
  padding-bottom: .2em;
  position: fixed;
  text-align: left;
  width: 80%;

  .prompt {
    font-size: 1.8em;
    padding-bottom: .4em;
  }

  .back-button {
    float: left;
    margin-left: 0px;
  }

  .next-button {
    float: left;
    margin-left: 0px;
  }



@media(min-width: $mobile-breakpoint-min){
  $file-height: 5em;

  /* Might need adjusting depending on the chosen height */
  $padding-percent: 0.35;

  .big-file-input > label {
    text-align: left;
    height: $file-height;
    opacity: 0.8;

    /* Add a percent of height as padding-top for centered text */
    padding-top: $file-height * $padding-percent;
  }

  .big-file-input > label::after {
     height: $file-height;
     padding-top: ($file-height) * $padding-percent;
  }
}

.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: gray;
}


  /* Optionally hide the "Browse" button
  .big-file-input > label::after {
    display: none;
  }*/

}
