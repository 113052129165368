.bg_archive {
  background-image: url("../images/Texere_BG_02.gif");
  background-size: cover;
  background-attachment: fixed;
  //width: 100vw;
  //height: 100vh;
}

.archive_container {
  padding: 5%;
  a:hover{
    background-color: #000000;
    color:  white;
    text-decoration: none;
  }
}

.category_list_container {
  //defaults to column on mobile
  display: grid;
  .category_heading {
    font-size: 1.2em;
    padding-top: .3em;
  }

  .category_item {
    margin: 2em;
    .gallery_tapestry_img {
      width: 50vw;
    }
  }
}


@media(min-width: $mobile-breakpoint-min){
  .category_list_container {
    grid-row-gap: 3em;
    .category_item {
      .gallery_tapestry_img {
        width: 20vw;
      }
    }
  }
  //don't need to control placeholder size on mobile bc displayed in one column
  .placeholder {
    height: 20vw;
    width: 20vw;
    margin-bottom: 0px;
  }
  .category_list_container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.stand_alone_tapestry_img {
  @media (orientation: landscape) {
    height: 100vw;
  }
  @media (orientation: portrait) {
    height: 100vh;
  }

  display: block;
  margin-left: auto;
  margin-right: auto;
}

.single_tapestry_page_container {
  background-color: black;
  .tapestry_container {
    background-color: black;
  }
  .button {
    color: white;
    border-color: white;
  }
}

.latest_page_container {
  background-color: black;
  .tapestry_container {
    background-color: black;
  }
  .latest_tapestry_img {
    opacity: 0;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 300px;
    background-color: black;
    height: 80%;
  }
}
