.category_archive_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: black;

  .tapestry_img {
    height: 40vh;
    margin: 3em;
  }
}
