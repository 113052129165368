
.waiting-area {
  //background-color: rgba(0, 0, 0, 0.85);
  background-image: url("../images/Texere_BG_03.gif");
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .waiting-logo {
    padding: 2em;
    width: 60vw;
    @media(min-width: $mobile-breakpoint-min) {
      height: 55vh;
    }
  }
  .waiting-message {
    margin: 5em;
    text-align: center;
    color: black;
    font-size: 18px;
  }
  .error {
    border-color: red;
    a {
      text-decoration: underline;
      color: black;
    }
  }
}
