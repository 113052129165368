#app {
  background-image: url("../images/Texere_BG_02.gif");
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.page-container {
  display: flex;
  font-family: "DM Sans", serif;
  justify-content: left;
  margin-left: 5%;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 5%;
}

.small-logo {
  height: 8em;
  padding-left: 2em;
}
@media (max-width: 599px) {
  .small-logo {
    display: none;
  }
}

.work-area {
  bottom: 0;
  color: black;
  padding: 2em;
  padding-bottom: 0.2em;
  position: fixed;
  text-align: left;
  width: 80%;
  /* Optionally hide the "Browse" button
  .big-file-input > label::after {
    display: none;
  }*/
}
.work-area .prompt {
  font-size: 1.8em;
  padding-bottom: 0.4em;
}
.work-area .back-button {
  float: left;
  margin-left: 0px;
}
.work-area .next-button {
  float: left;
  margin-left: 0px;
}
@media (min-width: 600px) {
  .work-area {
    /* Might need adjusting depending on the chosen height */
  }
  .work-area .big-file-input > label {
    text-align: left;
    height: 5em;
    opacity: 0.8;
    /* Add a percent of height as padding-top for centered text */
    padding-top: 1.75em;
  }
  .work-area .big-file-input > label::after {
    height: 5em;
    padding-top: 1.75em;
  }
}
.work-area .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: gray;
}

.active {
  color: black;
  font-weight: 900;
  text-decoration: underline;
}

.tab_choice {
  font-weight: 900;
  cursor: pointer;
}

.instructions {
  color: black;
  font-style: italic;
  font-weight: normal;
  padding-top: 1em;
}
@media (min-width: 600px) {
  .instructions {
    padding-top: 4em;
  }
}

.emphasis {
  color: black;
  font-weight: 900;
}

.back-button {
  padding-top: 2em;
  float: left;
}

.waiting-area {
  background-image: url("../images/Texere_BG_03.gif");
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.waiting-area .waiting-logo {
  padding: 2em;
  width: 60vw;
}
@media (min-width: 600px) {
  .waiting-area .waiting-logo {
    height: 55vh;
  }
}
.waiting-area .waiting-message {
  margin: 5em;
  text-align: center;
  color: black;
  font-size: 18px;
}
.waiting-area .error {
  border-color: red;
}
.waiting-area .error a {
  text-decoration: underline;
  color: black;
}

.bg_archive {
  background-image: url("../images/Texere_BG_02.gif");
  background-size: cover;
  background-attachment: fixed;
}

.archive_container {
  padding: 5%;
}
.archive_container a:hover {
  background-color: #000000;
  color: white;
  text-decoration: none;
}

.category_list_container {
  display: grid;
}
.category_list_container .category_heading {
  font-size: 1.2em;
  padding-top: 0.3em;
}
.category_list_container .category_item {
  margin: 2em;
}
.category_list_container .category_item .gallery_tapestry_img {
  width: 50vw;
}

@media (min-width: 600px) {
  .category_list_container {
    grid-row-gap: 3em;
  }
  .category_list_container .category_item .gallery_tapestry_img {
    width: 20vw;
  }
  .placeholder {
    height: 20vw;
    width: 20vw;
    margin-bottom: 0px;
  }
  .category_list_container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.stand_alone_tapestry_img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media (orientation: landscape) {
  .stand_alone_tapestry_img {
    height: 100vw;
  }
}
@media (orientation: portrait) {
  .stand_alone_tapestry_img {
    height: 100vh;
  }
}

.single_tapestry_page_container {
  background-color: black;
}
.single_tapestry_page_container .tapestry_container {
  background-color: black;
}
.single_tapestry_page_container .button {
  color: white;
  border-color: white;
}

.latest_page_container {
  background-color: black;
}
.latest_page_container .tapestry_container {
  background-color: black;
}
.latest_page_container .latest_tapestry_img {
  opacity: 0;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 300px;
  background-color: black;
  height: 80%;
}

body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  background-color: transparent;
}

.title {
  text-align: center;
  padding-top: 5em;
}
.title .responsive {
  padding: 2em;
  width: 60vw;
}
@media (min-width: 600px) {
  .title .responsive {
    height: 55vh;
  }
}

.bg_welcome {
  background-image: url("../images/weave-bg.gif");
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}
a {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.row {
  position: relative;
  max-width: 100%;
  text-align: center;
  padding-bottom: 100px;
  align-items: center;
  justify-content: center;
}

.column {
  font-family: "DM Sans", sans-serif;
  padding-top: 20px;
  display: inline-flex;
}

.footer {
  color: #000000;
  background-color: transparent;
  text-align: center;
  flex: 50%;
  padding: 1em;
  font-size: 12px;
}
.footer a:hover {
  background-color: #000000;
  color: white;
  text-decoration: none;
}

.locale-picker {
  padding-bottom: 2em;
}
.locale-picker ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.locale-picker li {
  padding: 0 0.7em;
}

.caption {
  padding: 1em;
  text-align: center;
}
.caption h2 {
  font-size: 1.5em;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
@media (max-width: 599px) {
  .caption h2 {
    font-size: 1em;
  }
}

.button-container {
  padding-top: 2em;
  padding-bottom: 5em;
}

.button {
  padding: 5px 32px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 16px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 3em;
  margin-right: 3em;
  transition-duration: 0.4s;
  cursor: pointer;
  background-color: transparent;
  color: black;
  border: 3px solid #000000;
  border-radius: 40px;
  font-family: "DM Sans", sans-serif;
}

.button:hover {
  background-color: #000000;
  color: white;
}

.button a:hover {
  background-color: #000000;
  color: white;
  text-decoration: none;
}

.category_archive_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: black;
}
.category_archive_container .tapestry_img {
  height: 40vh;
  margin: 3em;
}

.bg_about {
  background-image: url("../images/weave-bg.gif");
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}

.about_page_container {
  display: flex;
  flex-direction: column;
  font-family: "DM Sans", serif;
  justify-content: left;
  padding: 5%;
}
.about_page_container .about_content {
  margin-top: 3%;
}
.about_page_container .about_content h4 {
  padding-bottom: 1em;
  font-size: 1.5em;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2em;
}
.about_page_container .about_content p {
  padding-bottom: 2em;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}
.about_page_container .about_small_logo {
  height: 10em;
}