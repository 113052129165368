.bg_about {
  background-image: url("../images/weave-bg.gif");
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}

.about_page_container {
  display: flex;
  flex-direction: column;
  font-family: 'DM Sans', serif;
  justify-content: left;
  padding: 5%;

  .about_content {
    margin-top: 3%;
    h4 {
      padding-bottom: 1em;
      font-size: 1.5em;
      margin-bottom: .5rem;
      font-weight: 500;
      line-height: 1.2em;
    }
    p {
      padding-bottom: 2em;
      line-height: 1.5;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      text-align: left;
    }
  }

  .about_small_logo {
    height: 10em;
  }
}
