.active {
  color: black;
  font-weight: 900;
  text-decoration: underline;
}

.tab_choice {
  font-weight: 900;
  cursor: pointer;
}

.instructions {
  color: black;
  font-style: italic;
  font-weight: normal;
  padding-top: 1em;
  @media(min-width: $mobile-breakpoint-min){
    padding-top: 4em;
  }
}
